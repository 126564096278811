.projects__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.5rem;
    padding: 2rem !important;
}

.project__item {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition) !important;
}

.project__item:hover {
    background: var(--color-hover);
    border-color: var(--color-bg-variant);
    cursor: default;
}

.project__item-image img {
    border-radius: 1.5rem;
    aspect-ratio: 2/1;
    background: transparent;
}

.project__item p {
    margin-bottom: 1rem;
}

.project__item h3 {
    margin: 1.2rem 0 2rem;
}

.project__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* TABLET STYLES */
@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* MOBILE STYLES */
@media screen and (max-width: 600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
