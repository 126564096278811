header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* SOCIALS */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* PROFILE IMAGE */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: relative;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
}

/* SCROLL DOWN */
.scroll__down {
    background: transparent;
    color: var(--color-primary);
    transition: var(--transition);
    cursor: pointer;
    position: absolute;
    bottom: 5rem;
    right: -2rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.scroll__down:hover {
    color: var(--color-white);
}

/* TABLET STYLES */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* MOBILE STYLES */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}
