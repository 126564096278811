@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
    --color-bg: #191919;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, o.6);
    --color-hover: rgb(44, 44, 108, 0.5);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}

/* GENERAL STYLES */
h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

button.btn-primary {
    font-size: 1rem;
}

.btn {
    width: max-content;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

.btn-flex {
    display: inline-flex !important;
    align-items: center;
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

.text-light {
    color: var(--color-light);
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.swiper-wrapper {
    cursor: grab;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

/* TABLET STYLES */
@media screen and (max-width: 1024px) {
    section {
        margin-top: 6rem;
    }

    .container {
        width: var(--container-width-md);
    }
}

/* MOBILE STYLES */
@media screen and (max-width: 600px) {
    section > h2 {
        margin-bottom: 2rem;
    }

    .btn-flex {
        padding: 0.7rem !important;
    }

    .container {
        width: var(--container-width-sm);
    }
}
